<template>
  <div class="modal fade" id="excel-import-mapping-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Mapping</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center" v-if="loadingMapping">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
          <table class="table table-bordered" v-else>
            <thead>
            <tr>
              <th>Column</th>
              <th>Field</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(header, i) in headers" :key="'header_'+i">
              <td>{{ header.header }}</td>
              <td>
                <select class="form-select form-select-sm" v-model="header.column">
                  <option value="">Select Column</option>
                  <template v-for="(column, j) in columns" :key="'c'+j">
                    <option :value="column"
                            v-if="header.column === column || !headers.find(h => h.column === column)">{{ column }}</option>
                  </template>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer" v-if="!loadingMapping">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">Cancel</button>
          <button type="button"
                  :disabled="!headers.length || !headers[0].column"
                  @click.prevent="submit"
                  class="btn btn-success float-end">Import</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingMapping: false,
      file: null,
      headers: [],
      columns: ['ID', 'First Name', 'Middle Name', 'Last Name', 'Contact Phone Number',
        'Date of Birth'],
      modal: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('excel-import-mapping-modal'));
  },
  methods: {
    show(file) {
      this.file = file;
      this.loadHeaders(file);
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadHeaders(file) {
      this.loadingMapping = true;
      this.headers = [];

      let formData = new FormData();

      formData.append('file', file);

      this.axios.post('/import/response/header', formData)
        .then((res) => {
          this.headers = res.data.map(header => {
            return {
              header: header,
              column: ''
            }
          });
        })
        .finally(() => {
          this.loadingMapping = false;
        });
    },
    submit() {
      this.allErrors = null;
      this.loadingMapping = true;

      let selectedColumns = this.headers.map(header => header.column);

      let formData = new FormData();

      formData.append('file', this.file);

      selectedColumns.forEach(c => {
        formData.append('columns[]', c);
      })


      this.axios.post('/import/response', formData)
        .then(() => {
          this.$emit('added');
          this.hide();
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loadingMapping = false;
        });
    }
  }
}
</script>